<template>
  <div id="healthcare">
    <breadcrumb class="mt-2" :item="$route.matched" :current="$route.path" />
    <h4 class="mt-4"> Information for Healthcare Professionals </h4>

    <hr />

    <div class="myozyme">
      <p> <b> Myozyme<sup>®</sup>. </b> </p>
      <p class="mb-2"> <b>DS</b> : Alglucosidase alfa (1 vial = 50 mg).</p>
      <p class="mb-2"> <b>I</b> : Long-term enzyme replacement therapy in patients with confirmed Pompe disease (deficiency of acid α glucosidase). </p>
      <p class="mb-2"> <b>D</b> : 20 mg/kg body weight once every 2 weeks as an intravenous infusion. </p>
      <p class="mb-2"> <b>CI</b> : Hypersensitivity (anaphylactic reaction) to one of the ingredients. Breastfeeding. </p>
      <p class="mb-2"> <b>IA</b> : Serious and life-threatening anaphylactic reactions have been observed. During treatment, ensure that appropriate medical support measures, including cardiopulmonary resuscitation equipment, are readily available. </p>

      <p>
        If anaphylactic reactions occur, discontinue immediately. Increased antibody formation within 3 months is likely and seroconversion is to be expected. This is more probable in CRIM-negative than in CRIM-positive patients. There are indications that early initiation of treatment regimens to induce immune tolerance can improve treatment success. Continued treatment is possible in the event of severe reactions after closely monitored successful hyposensitisation. 
      </p>

      <p>
        Only perform desensitisation in consultation with the Risk Management Department at sanofi-aventis (schweiz) ag and only on the intensive care unit. Infusion-mediated reactions were more common in the presence of antibodies, in acute disease and at higher dosages (40 mg/kg). The patient's clinical status should therefore be carefully considered and closely monitored. Severe cutaneous, potentially immune-mediated reactions including ulcerative and necrotising skin lesions have been reported. Discontinue treatment.
      </p>

      <p>
        Only administer again after a risk-benefit assessment. Transient nephrotic syndrome may occur. The condition improves after treatment is interrupted. Perform periodic urinalysis. Treatment with immunosuppressive agents could increase the risk of developing severe respiratory infections. Carefully consider the risks and benefits of treatment. 
      </p>

      <p class="mb-2"> <b>SE</b> : Fever, urticaria, exanthema, cough, tachypnoea, tachycardia. </p>
      <p class="mb-2"> <b>P</b> : 1 vial and 25 vials. </p>
      <p class="mb-2"> <b>DC</b> : A*. </p>
      <p class="mb-2"> <b>MAH</b> : sanofi-aventis (schweiz) ag, 1214 Vernier/GE. </p>

      <p> <b>Version</b> : April 2020 (MAT-CH-2001118 - 1.0 - 06/2020). For further information, please see the summary of product characteristics at <a href="https://swissmedicinfo.ch" class="anchor-link" target="_blank">www.swissmedicinfo.ch.</a></p>
    </div>

    <hr />

    <div class="nexviadyme">
      <p> <b> Nexviadyme<sup>®</sup>. </b> </p>

      <p class="mb-2"> <b>AS</b> : Avalglucosidase alfa (1 vial = 100 mg). </p>
      <p class="mb-2"> <b>I</b> : Long-term enzyme replacement therapy for patients with late-onset Pompe disease (LOPD) (acid α−glucosidase deficiency). </p>
      <p class="mb-2"> <b>D</b> : 20 mg/kg body weight once every two weeks as intravenous infusion. </p>
      <p class="mb-2"> <b>CI</b> : Hypersensitivity (anaphylactic reaction) to one of the components. </p>

      <p class="mb-2">
        <b>W&P</b>: Appropriate medical measures, including equipment for cardiopulmonary resuscitation, should be available in the event of severe and life-threatening anaphylactic reactions. Discontinue infusion immediately if these reactions occur. Discontinue infusion immediately if serious infusion-related reactions occur. 
      </p>
      <p class="mb-2">
        Administration may be recommenced only after risk-benefit assessment. Increased antibody formation is to be expected in naïve and previously treated patients. If antibodies were present, infusion-related reactions occurred more frequently in naïve patients. For this reason, the clinical status of the patient should be evaluated carefully and monitored closely. Patients with elevated IgG antibodies must undergo regular urine analyses. 
      </p>
      <p class="mb-2">
        In the case of severe reactions, continued treatment is possible following desensitisation subject to strict observation. Appropriate medical measures and extended monitoring are recommended for patients with acute respiratory disorders or impaired cardiac and/or respiratory function. 
      </p>

      <p class="mb-2">
        <b>AR</b>: Hypersensitivity, anaphylaxis, ocular hyperaemia, cephalgia, vertigo, tremor, hypertension, cough, dyspnoea, nausea, diarrhoea, emesis, pruritus, exanthema, urticaria, erythema, muscle contractions, myalgia, fatigue. 
      </p>

      <p class="mb-2"> <b>P</b> : 1, 5 and 10 vials. </p>
      <p class="mb-2"><b>DC</b> : A*. Auth. hold.: sanofi-aventis (Schweiz) AG, 1214 Vernier/GE. </p>
      <p class="mb-2">
        <b>As of</b> : November 2021. For further information, see the Summary of Product Characteristics at <a href="https://swissmedicinfo.ch" class="anchor-link" target="_blank">www.swissmedicinfo.ch.</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'healthcareInfo'
}
</script>

<style lang="scss" scoped>
p {
  font-size: 13.5px;
  text-align:justify;
}
</style>